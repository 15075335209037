import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { text, textWrapper } from './collapse';

const CollapsedNav = ({ active, initialClosed = true, children }) => (
  <AnimatePresence>
    {active && (
      <motion.nav
        className="nav"
        key="nav"
        style={{ overflow: 'hidden' }}
        variants={textWrapper}
        initial={initialClosed ? 'close' : 'open'}
        animate={active ? 'open' : 'close'}
        exit={initialClosed ? 'close' : 'open'}
      >
        <motion.ul
          key="text"
          variants={text}
          initial={initialClosed ? 'hidden' : 'show'}
          animate={active ? 'show' : 'hidden'}
          exit={initialClosed ? 'hidden' : 'show'}
        >
          {children}
        </motion.ul>
      </motion.nav>
    )}
  </AnimatePresence>
);

CollapsedNav.propTypes = {
  active: PropTypes.bool,
  initialClosed: PropTypes.bool,
  children: PropTypes.node,
};

export default CollapsedNav;
