import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

export const textWrapper = {
  open: {
    height: 'auto',
    transition: { ease: 'easeOut', duration: 0.4 },
  },
  close: {
    height: 0,
    transition: { ease: 'easeOut', duration: 0.4 },
  },
};

export const text = {
  hidden: { opacity: 0, transition: { duration: 0.15 } },
  show: { opacity: 1, transition: { duration: 0.2, delay: 0.2 } },
};

const Collapse = ({ active, initialClosed = true, children }) => (
  <AnimatePresence>
    {active && (
      <motion.div
        style={{ overflow: 'hidden' }}
        variants={textWrapper}
        initial={initialClosed ? 'close' : 'open'}
        animate={active ? 'open' : 'close'}
        exit={initialClosed ? 'close' : 'open'}
      >
        <StyledCollpase
          key="text"
          variants={text}
          initial={initialClosed ? 'hidden' : 'show'}
          animate={active ? 'show' : 'hidden'}
          exit={initialClosed ? 'hidden' : 'show'}
        >
          {children}
        </StyledCollpase>
      </motion.div>
    )}
  </AnimatePresence>
);

const StyledCollpase = styled(motion.ul)`
  cursor: pointer;
`;

Collapse.propTypes = {
  active: PropTypes.bool,
  initialClosed: PropTypes.bool,
  children: PropTypes.node,
};

export default Collapse;
