/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { motion, useViewportScroll } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useClientSide } from '../../hooks/useClientSide';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useEventListener } from '../../hooks/useEventListener';
import { media } from '../../utils/media-queries';
import HoverUnderline from '../atoms/hoverUnderline';
import FooterItem, { InternalFooterLink } from '../molecules/footerItem';
import SiteCredits from './siteCredits';
import { LayoutContext } from './layoutProvider';

const Footer = () => {
  const { footer } = useStaticQuery(graphql`
    query Footer {
      footer: allSanityFooter {
        nodes {
          mail
          phone
          description
          linkedinUrl
          instagram
          facebook
          copyright
        }
      }
    }
  `);

  const {
    mail,
    phone,
    linkedinUrl,
    instagram,
    facebook,
    description,
    copyright,
  } = footer.nodes[0];

  const ref = useRef();
  const size = useWindowSize();
  const isClient = useClientSide();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (size.width < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [size]);

  const [footerHeight, setFooterHeight] = useState(0);
  const [triggerFooterReveal, setTriggerFooterReveal] = useState(false);
  const { scrollYProgress } = useViewportScroll();
  const [showSiteCredits, setShowSiteCredits] = useState(false);
  const [hover, setHover] = useState(false);

  const { setClickedClose } = useContext(LayoutContext);

  const scale = isMobile ? 0.25 : 0.5;
  const radius = isMobile ? 72.5 : 145;
  const contact = {
    width: isMobile ? 150 : 300,
    height: isMobile ? 40 : 80,
  };
  const desc = {
    width: isMobile ? 250 : 500,
    height: isMobile ? 85 : 170,
  };
  const close = {
    width: isMobile ? 300 : 600,
    height: isMobile ? 85 : 170,
  };

  useEffect(
    () => {
      scrollYProgress.onChange(() => {
        if (scrollYProgress.current >= 0.97) {
          setTriggerFooterReveal(true);
        } else {
          setTriggerFooterReveal(false);
        }
      });
      setFooterHeight(ref.current.clientHeight);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    size,
    scrollYProgress
  );

  useEventListener(
    'resize',
    () => setShowSiteCredits(false),
    isClient && window
  );

  return (
    <StyledFooter ref={ref}>
      <motion.div
        className="wrapper"
        initial={{ y: footerHeight, opacity: 0 }}
        animate={{
          y: triggerFooterReveal ? 0 : footerHeight,
          opacity: triggerFooterReveal ? 1 : 0,
        }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        <div className="left">
          <div className="contact">
            <FooterItem className="spacing-right" text={mail} mailto={mail} />
            <FooterItem text={phone} mailto={phone} />
          </div>
          <p className="desc">{description}</p>
        </div>
        <div className="right">
          <div className="social">
            <FooterItem
              className="spacing-right"
              text="LinkedIn"
              link={linkedinUrl}
            />
            <FooterItem
              className="spacing-right centered"
              text="Instagram"
              link={instagram}
            />
            <FooterItem
              text="Facebook"
              link={facebook}
              className="right-aligned"
            />
          </div>
          <div className="credits">
            <div className="links">
              <InternalFooterLink
                className="spacing-right"
                text="Privacy Policy"
                link="privacypolicy"
              />
              <InternalFooterLink
                className="spacing-right centered"
                text="Cookies"
                link="cookies"
              />
              <a
                className="spacing-right site-credits right-aligned"
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => {
                  setClickedClose(false);
                  setShowSiteCredits(!showSiteCredits);
                }}
              >
                Site Credits
                <HoverUnderline active={hover} />
              </a>
            </div>
            <p>© {copyright}</p>
          </div>
        </div>
      </motion.div>
      {showSiteCredits && (
        <SiteCredits
          scale={scale}
          contact={contact}
          desc={desc}
          radius={radius}
          close={close}
        />
      )}
    </StyledFooter>
  );
};
const StyledFooter = styled(motion.footer)`
  color: var(--blue);
  overflow: hidden;

  .contact,
  .social {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: var(--font-size-2);
    padding-bottom: var(--v-spacing-S);
  }

  .social {
    * {
      flex: 1;
    }
  }

  .links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    * {
      flex: 1;
    }
  }

  .centered {
    text-align: center;
  }

  .right-aligned {
    text-align: right;
  }

  .desc {
    max-width: 60%;
    padding-bottom: var(--v-spacing-M);
  }

  .world {
    position: fixed !important;
    bottom: 100px;
    left: 0;
    z-index: 3;
    pointer-events: none;
  }

  .item {
    pointer-events: all;
    background: white;
    height: auto;
    padding: var(--spacing-XXS);
    .link {
      color: var(--red);
      font-size: var(--font-size-1);
      font-family: var(--font-1);
    }
    .overline,
    .bold-big {
      padding-bottom: 0;
    }
  }

  .site-credits {
    position: relative;
    display: inline-block;
  }

  @media ${media.M} {
    .wrapper {
      display: flex;
      justify-content: space-between;
      padding-bottom: 2px;
    }

    .spacing-right {
      margin-right: var(--v-spacing-S);
    }

    .top,
    .disclaimer {
      display: flex;
      justify-content: space-between;
    }

    .contact,
    .social {
      display: block;
      font-size: var(--font-size-1);
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .credits,
    .desc {
      display: flex;
      font-size: var(--font-size-0);
    }

    .desc {
      max-width: 100%;
      padding-bottom: 0;
    }

    .credits {
      justify-content: flex-end;
    }

    .social {
      * {
        flex: auto;
      }
    }

    .links {
      * {
        flex: auto;
      }
    }

    .centered {
      text-align: start;
    }

    .right-aligned {
      text-align: start;
    }
  }

  @media ${media.XS} {
    .social,
    .links,
    .contact {
      flex-direction: column;
      * {
        flex: 0;
        text-align: left;
        padding-bottom: var(--spacing-XXS);
      }
    }
  }
`;

export default Footer;
