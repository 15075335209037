import React, { useContext } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { LayoutContext } from '../organisms/layoutProvider';
import { useClientSide } from '../../hooks/useClientSide';

const Darken = () => {
  const isClient = useClientSide();
  const height = isClient && document.body.scrollHeight;
  const { darkenBg } = useContext(LayoutContext);

  return (
    <AnimatePresence>
      {darkenBg && (
        <StyledDarken
          key="darken"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.2 }}
          exit={{ opacity: 0 }}
          style={{ height }}
        />
      )}
    </AnimatePresence>
  );
};

const StyledDarken = styled(motion.div)`
  width: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export default Darken;
