import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../../utils/media-queries';

const HoverUnderline = ({ active, donate }) => {
  const transition = { duration: 0.3, ease: 'easeOut' };
  const fullStroke = 'inset(0 1% 0 1%)';
  const strokeIn = 'inset(0 100% 0 1%)';
  const strokeOut = 'inset(0 1% 0 100%)';

  return (
    <AnimatePresence>
      {active && (
        <StyledHoverUnderline
          initial={{ clipPath: strokeIn }}
          animate={{ clipPath: fullStroke }}
          exit={{ clipPath: strokeOut }}
          transition={transition}
          style={{ background: donate ? 'var(--red)' : 'var(--blue)' }}
        />
      )}
    </AnimatePresence>
  );
};

const StyledHoverUnderline = styled(motion.div)`
  display: none;

  @media ${media.L} {
    display: block;
    z-index: 10;
    width: 100%;
    height: 2px;
    position: absolute;
  }
`;

HoverUnderline.propTypes = {
  active: PropTypes.bool,
  donate: PropTypes.bool,
};

export default HoverUnderline;
