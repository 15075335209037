/* eslint-disable no-restricted-globals */
import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Matter from 'matter-js';
import { useWindowSize } from '../../hooks/useWindowSize';
import AnnaPhoto from '../../assets/images/site-credits/Kwera_Credits_Anna_Photo.png';
import JacobPhoto from '../../assets/images/site-credits/Kwera_Credits_Jacob_Photo.png';
import Anna from '../../assets/images/site-credits/Kwera_Credits_Anna.png';
import Jacob from '../../assets/images/site-credits/Kwera_Credits_Jacob.png';
import AnnaContact from '../../assets/images/site-credits/Kwera_Credits_Contact-Anna.png';
import JacobContact from '../../assets/images/site-credits/Kwera_Credits_Contact-Jacob.png';
import Close from '../../assets/images/site-credits/Kwera_Credits_Close.png';
import { LayoutContext } from './layoutProvider';

const MatterCanvas = ({ scale, contact, radius, desc, close }) => {
  const { clickedClose, setClickedClose } = useContext(LayoutContext);
  const sketchRef = useRef(null);
  const canvasRef = useRef(null);

  const {
    Engine,
    Render,
    World,
    Bodies,
    Mouse,
    MouseConstraint,
    Events,
  } = Matter;

  const size = useWindowSize();
  const { width, height } = size;

  const transparentColor = 'rgba(0,0,0,0)';
  const wallsWidth = 10;
  const friction = 1;
  const restitution = 0;

  useEffect(() => {
    const engine = Engine.create({});
    const { world } = engine;
    const render = Render.create({
      element: sketchRef.current,
      engine,
      canvas: canvasRef.current,
      options: {
        width,
        height,
        background: 'rgba(0, 0, 0, 0.65)',
        wireframes: false,
        pixelRatio: 'auto',
      },
    });

    /**
     * Walls
     */

    const createWalls = () => {
      const top = Bodies.rectangle(width / 2, 0, width, wallsWidth * 10, {
        isStatic: true,
        render: {
          fillStyle: transparentColor,
        },
      });

      const floor = Bodies.rectangle(width / 2, height, width, wallsWidth, {
        isStatic: true,
        render: {
          fillStyle: transparentColor,
        },
      });

      const wallLeft = Bodies.rectangle(0, height / 2, wallsWidth, height, {
        isStatic: true,
        render: {
          fillStyle: transparentColor,
        },
      });

      const wallRight = Bodies.rectangle(
        width,
        height / 2,
        wallsWidth * 5,
        height,
        {
          isStatic: true,
          render: {
            fillStyle: transparentColor,
          },
        }
      );

      return World.add(world, [floor, wallLeft, wallRight, top]);
    };

    createWalls();

    const jacobPhoto = Bodies.circle(radius, height - radius, radius, {
      restitution,
      friction,
      render: {
        sprite: {
          texture: JacobPhoto,
          xScale: scale,
          yScale: scale,
        },
      },
    });

    const annaPhoto = Bodies.circle(
      width - radius * 2,
      height - radius,
      radius,
      {
        restitution,
        friction,
        render: {
          sprite: {
            texture: AnnaPhoto,
            xScale: scale,
            yScale: scale,
          },
        },
      }
    );

    const anna = Bodies.rectangle(
      width / 1.5,
      height - desc.height,
      desc.width,
      desc.height,
      {
        restitution,
        friction,
        render: {
          sprite: {
            texture: Anna,
            xScale: scale,
            yScale: scale,
          },
        },
      }
    );

    const jacob = Bodies.rectangle(
      height / 3,
      desc.height / 2,
      desc.width,
      desc.height,
      {
        restitution,
        friction,
        render: {
          sprite: {
            texture: Jacob,
            xScale: scale,
            yScale: scale,
          },
        },
      }
    );

    const annaContact = Bodies.rectangle(
      width - 100,
      height - 400,
      contact.width,
      contact.height,
      {
        label: 'anna-btn',
        restitution,
        render: {
          sprite: {
            texture: AnnaContact,
            xScale: scale,
            yScale: scale,
          },
        },
      }
    );

    const jacobContact = Bodies.rectangle(
      300,
      height - 150,
      contact.width,
      contact.height,
      {
        label: 'jacob-btn',
        restitution,
        render: {
          sprite: {
            texture: JacobContact,
            xScale: scale,
            yScale: scale,
          },
        },
      }
    );

    const closed = Bodies.rectangle(
      width - close.width,
      close.width,
      close.width,
      close.height,
      {
        label: 'close-btn',
        restitution,
        render: {
          sprite: {
            texture: Close,
            xScale: scale,
            yScale: scale,
          },
        },
      }
    );

    World.add(world, [
      anna,
      annaPhoto,
      jacobPhoto,
      jacob,
      annaContact,
      jacobContact,
      closed,
    ]);

    const mouse = Mouse.create(render.canvas);
    const mouseConstraint = MouseConstraint.create(engine, {
      mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    });

    World.add(world, mouseConstraint);

    render.mouse = mouse;

    Events.on(mouseConstraint, 'mousedown', function (event) {
      const { body } = mouseConstraint;
      if (body && body.label === 'close-btn') {
        setClickedClose(true);
      } else if (body && body.label === 'anna-btn') {
        window.location.href = 'https://www.annaehrnsperger.de/';
      } else if (body && body.label === 'jacob-btn') {
        window.location.href = 'https://jacoblindblad.dk/';
      }
    });

    Engine.run(engine);
    Render.run(render);
  }, []);

  return (
    !clickedClose && (
      <StyledSketch ref={sketchRef}>
        <canvas ref={canvasRef} width={width} height={height} />
      </StyledSketch>
    )
  );
};

const StyledSketch = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
`;

export default MatterCanvas;
