import { createGlobalStyle } from 'styled-components';
import NeueMontrealMedium from '../assets/fonts/NeueMontreal-Medium.woff2';
import NeueMontrealBold from '../assets/fonts/NeueMontreal-Bold.woff2';
import { media } from '../utils/media-queries';

const GlobalStyles = createGlobalStyle`

  html {
    font-family: var(--font-1);
    color: var(--text-color);
    background: var(--bg-color);
    font-size: 10px;
  }

  body {
    font-size: var(--font-size-1);
    padding: var(--v-spacing-S);
    padding-top: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .darken {
    background: rgba(0, 0, 0, .65);
  }

  /* Typography */

  @font-face {
    font-family: 'NeueMontreal Medium';
    src: url(${NeueMontrealMedium});
  }

  @font-face {
    font-family: 'NeueMontreal Bold';
    src: url(${NeueMontrealBold});
  }

  .overline, .overline-mobile {
    color: var(--blue);
    padding-bottom: var(--spacing-XXXS);
    margin-top: -3px;
  }
  
  .bold-big {
    font-family: var(--font-2);
    font-size: var(--font-size-3);
    padding-bottom: var(--v-spacing-M);
    color: var(--blue);
  }
  
  .bold-small {
    font-family: var(--font-2);
    font-size: var(--font-size-2);
    padding-bottom: var(--v-spacing-M);
    color: var(--blue);
  }

  .copy {
    color: var(--red);
  }

  .text-gradient {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  input:focus {
    outline: 1px solid var(--blue);
    color: var(--red);
  }
  
  /* Media */
  
  .gatsby-image-wrapper {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  /* Scrollbar */
  
  /* .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    &::-webkit-scrollbar {
      display: none;
    }
  } */
  
  /* Layout */

  .spacing-outer {
    margin: var(--v-spacing-S);
  }

  .spacing-inner {
    padding: var(--v-spacing-S);
  }

  .spacing-bottom {
    margin-bottom: var(--v-spacing-M);
  }

  .spacing-removed {
    margin-right: calc(var(--v-spacing-S) * -1);
  }

  .spacing-side-removed {
    margin-right: calc(var(--v-spacing-S) * -1);
    margin-left: calc(var(--v-spacing-S) * -1);
  }

  .spacing-top-small {
    padding-top: 10vh;
  }

  .spacing-top-medium {
    padding-top: 15vh;
  }

  .spacing-top-big {
    padding-top: 20vh;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(var(--grid-cols), 1fr);
    gap: var(--spacing-XXXS);

    * {
      grid-column: 1 / calc(var(--grid-cols) + 1);
    }
  }

  label {
    background: #F4F4F4;
    color: rgba(0,0,0,0.3);
    height: calc(var(--v-spacing-L) * 1.5);
    border-radius: 6px;
  }

  .legal {
    color: var(--blue);
    font-size: var(--font-size-0);
    line-height: 1.3;
    padding-bottom: calc(var(--spacing-XL) * 2);

    .title {
      font-size: var(--font-size-1);
      padding-bottom: calc(var(--v-spacing-M) * 2);
    }

    p {
      padding-bottom: var(--spacing-XS);
    }

    strong {
      font-weight: normal;
      font-family: var(--font-2);
    }

    ol {
      counter-reset: number;
      li::before {
        content: counter(number);
        counter-increment: number;
      }
    }

    ul {
      li::before {
        content: '•';
      }
    }

    li {
      display: block;
      margin-left: var(--v-spacing-M);
    }

    li::before {
      display: inline-block;
      width: var(--v-spacing-M);
      margin-left: calc(var(--v-spacing-M) * -1);
    }

    a {
      text-decoration: underline;
    }
  }

  .legal.grid {
    * {
      grid-column: 1 / 20;
    }
  }

  @media ${media.L} {
    .grid {
      gap: var(--spacing-XXS);
    }

    .legal.grid {
      * {
        grid-column: 1 / 9;
      }
    }

    .spacing-top-big {
      padding-top: 30vh;
    }

    .spacing-bottom {
      margin-bottom: 0;
    }
  }

`;

export default GlobalStyles;
