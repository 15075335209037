import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import HoverUnderline from '../atoms/hoverUnderline';

const FooterItem = ({ text, mailto, link, onClick, style, className }) => {
  const [hover, setHover] = useState(false);

  return (
    <StyledFooterItem
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      style={style}
      className={className}
      href={mailto ? `mailto:${mailto}` : `${link}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
      <HoverUnderline active={hover} />
    </StyledFooterItem>
  );
};

export const InternalFooterLink = ({
  text,
  link,
  onClick,
  style,
  className,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <StyledFooterLinkInternal
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      style={style}
      className={className}
      to={`/${link}`}
    >
      {text}
      <HoverUnderline active={hover} />
    </StyledFooterLinkInternal>
  );
};

const StyledFooterLinkInternal = styled(Link)`
  display: inline-block;
  position: relative;
`;

const StyledFooterItem = styled.a`
  display: inline-block;
  position: relative;
`;

FooterItem.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  text: PropTypes.string,
  mailto: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
};

export default FooterItem;
