import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { text, textWrapper } from './collapse';
import { media } from '../../utils/media-queries';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useClientSide } from '../../hooks/useClientSide';

const CollapsedInfo = ({ active, initialClosed = true, children }) => {
  const size = useWindowSize();
  const isClient = useClientSide();
  const isMobile = isClient && size.width < 1024;

  const bgWrapper = {
    open: {
      height: 350,
      transition: { ease: 'easeOut', duration: 0.4 },
    },
    close: {
      height: 0,
      transition: { ease: 'easeOut', duration: 0.4 },
    },
  };

  return (
    <AnimatePresence>
      {active && (
        <>
          {!isMobile && (
            <StyledCollapsedInfoBG
              key="bg"
              style={{ overflow: 'hidden' }}
              variants={bgWrapper}
              initial={initialClosed ? 'close' : 'open'}
              animate={active ? 'open' : 'close'}
              exit={initialClosed ? 'close' : 'open'}
            />
          )}
          <StyledCollapsedInfo
            key="info"
            style={{ overflow: 'hidden', height: 'auto' }}
            variants={textWrapper}
            initial={isMobile && (initialClosed ? 'close' : 'open')}
            animate={isMobile && (active ? 'open' : 'close')}
            exit={isMobile && (initialClosed ? 'close' : 'open')}
          >
            <motion.ul
              key="text"
              className="info"
              variants={text}
              initial={initialClosed ? 'hidden' : 'show'}
              animate={active ? 'show' : 'hidden'}
              exit={initialClosed ? 'hidden' : 'show'}
            >
              {children}
            </motion.ul>
          </StyledCollapsedInfo>
        </>
      )}
    </AnimatePresence>
  );
};

const StyledCollapsedInfo = styled(motion.div)`
  @media ${media.L} {
    position: fixed;
    height: 1000px;
    ul {
      display: flex;
      flex-direction: column;
      li {
        margin-bottom: 0.2rem;
      }
    }
  }
`;

const StyledCollapsedInfoBG = styled(motion.div)`
  position: fixed;
  width: 100vw;
  left: 0;
  background: white;
`;

CollapsedInfo.propTypes = {
  active: PropTypes.bool,
  initialClosed: PropTypes.bool,
  children: PropTypes.node,
};

export default CollapsedInfo;
