import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { media } from '../../utils/media-queries';

const MenuButtonBig = ({ onClick, triggerAnim }) => {
  const duration = 0.3;
  const space = 6;

  const firstLine = {
    closed: {
      y: -space,
      transition: { duration },
    },
    open: {
      y: 0,
      transition: { duration },
    },
  };

  const thirdLine = {
    closed: {
      y: space,
      transition: { duration },
    },
    open: {
      y: 0,
      transition: { duration },
    },
  };

  return (
    <StyledMenuButtonBig onClick={onClick} className="menu-btn">
      <div className="wrapper">
        <motion.div
          className="line"
          variants={firstLine}
          initial="closed"
          animate={triggerAnim ? 'open' : 'closed'}
        />
        <motion.div className="line" />
        <motion.div
          className="line"
          variants={thirdLine}
          initial="closed"
          animate={triggerAnim ? 'open' : 'closed'}
        />
      </div>
    </StyledMenuButtonBig>
  );
};

const StyledMenuButtonBig = styled.button`
  width: 30px;
  height: 100%;
  margin: auto 0;
  display: flex;
  align-items: center;

  .line {
    width: 30px;
    height: 2px;
    background: var(--blue);
    position: absolute;
  }

  @media ${media.L} {
    display: none;
  }
`;

MenuButtonBig.propTypes = {
  onClick: PropTypes.func,
  triggerAnim: PropTypes.bool,
};

export default MenuButtonBig;
