import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { motion, useViewportScroll } from 'framer-motion';
import KweraLogo from '../../assets/svg/kwera-logo.svg';
import { media } from '../../utils/media-queries';
import MenuButtonBig from '../atoms/menuButtonBig';
import { useWindowSize } from '../../hooks/useWindowSize';
import CollapsedNav from '../molecules/collapsedNav';
import NavItem from '../molecules/navItem';
import { useClientSide } from '../../hooks/useClientSide';
import CollapsedInfo from '../molecules/collapsedInfo';
import { LayoutContext } from './layoutProvider';
import ArrowDesktop from '../../assets/images/arrow-desktop.png';
import ArrowMobile from '../../assets/images/arrow-mobile.png';
import SmallArrow from '../atoms/smallArrow';

const Header = () => {
  const { board } = useStaticQuery(graphql`
    query Board {
      board: allSanityDanishcollectionboard {
        nodes {
          pdf {
            asset {
              url
            }
          }
        }
      }
    }
  `);

  const { pdf } = board?.nodes[0];

  const size = useWindowSize();
  const isClient = useClientSide();
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    if (size.width < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [size]);

  const [open, setOpen] = useState(false);
  const [hideBurger, setHideBurger] = useState(true);
  const [openInfo, setOpenInfo] = useState(false);

  useEffect(() => (isMobile ? setOpen(false) : setOpen(true)), [isMobile]);
  useEffect(() => (isMobile ? setHideBurger(false) : setHideBurger(true)), [
    isMobile,
  ]);

  const { setDarkenBg } = useContext(LayoutContext);
  useEffect(() => {
    setDarkenBg(openInfo || (isMobile && open));
  });

  /**
   * SMALL LOGO
   */
  const { scrollYProgress } = useViewportScroll();
  const [smaller, setSmaller] = useState(0);

  const smallWidth = '80px';
  const desktopWidth = '100%';
  const mobileWidth = '75%';
  const transition = { duration: 0.4, ease: [0.6, 0.01, -0.05, 0.9] };

  useEffect(
    () => scrollYProgress.onChange(() => setSmaller(scrollYProgress.current)),
    [scrollYProgress]
  );

  const checkPath = (path) => {
    const url = isClient && window.location.pathname;
    return isClient && url.includes(path);
  };

  const handleNavItemClick = () => {
    if (isMobile) {
      setOpenInfo(false);
      setOpen(!open);
    }
  };

  return (
    <StyledHeader className="spacing-inner grid">
      <motion.h1
        className="logo"
        key="headline"
        initial={{ width: desktopWidth }}
        animate={
          smaller > 0.06 ? { width: smallWidth } : { width: desktopWidth }
        }
        exit={{ width: desktopWidth }}
        transition={transition}
      >
        <Link to="/">
          <KweraLogo />
        </Link>
      </motion.h1>
      <motion.h1
        className="logo-mobile"
        key="headline-mobile"
        initial={{ width: mobileWidth }}
        animate={
          smaller > 0.06 ? { width: smallWidth } : { width: mobileWidth }
        }
        exit={{ width: mobileWidth }}
        transition={transition}
      >
        <Link to="/">
          <KweraLogo />
        </Link>
      </motion.h1>
      <MenuButtonBig
        onClick={handleNavItemClick}
        triggerAnim={open}
        hidden={hideBurger}
      />
      <CollapsedNav active={open} initialClosed={isMobile}>
        <NavItem active={checkPath('challenge')} onClick={handleNavItemClick}>
          <Link to="/challenge" activeClassName="active-page">
            The Challenge
          </Link>
        </NavItem>
        <NavItem
          active={checkPath('solution')}
          className="spacing-right"
          onClick={handleNavItemClick}
        >
          <Link to="/solution" activeClassName="active-page">
            The Solution
          </Link>
        </NavItem>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!isMobile && <SmallArrow src={ArrowDesktop} active={openInfo} />}
          <NavItem
            active={
              checkPath('story') ||
              checkPath('team') ||
              checkPath('participate') ||
              checkPath('partners') ||
              checkPath('codeofconduct') ||
              checkPath('privacypolicy')
            }
            onClick={() => setOpenInfo(!openInfo)}
            style={{ position: 'relative', cursor: 'pointer' }}
          >
            {isMobile && <SmallArrow src={ArrowMobile} active={openInfo} />}
            Information
            <CollapsedInfo active={openInfo} initialClosed>
              <NavItem active={checkPath('story')} onClick={handleNavItemClick}>
                <Link to="/story" activeClassName="active-page">
                  Our Story
                </Link>
              </NavItem>
              <NavItem active={checkPath('team')} onClick={handleNavItemClick}>
                <Link to="/team" activeClassName="active-page">
                  Team
                </Link>
              </NavItem>
              <NavItem
                active={checkPath('participate')}
                onClick={handleNavItemClick}
              >
                <Link to="/participate" activeClassName="active-page">
                  Participate
                </Link>
              </NavItem>
              <NavItem
                active={checkPath('partners')}
                onClick={handleNavItemClick}
              >
                <Link to="/partners" activeClassName="active-page">
                  Partners
                </Link>
              </NavItem>
              <NavItem
                active={checkPath('codeofconduct')}
                className="small top"
                onClick={handleNavItemClick}
              >
                <Link to="/codeofconduct" activeClassName="active-page">
                  Code of Conduct
                </Link>
              </NavItem>
              <NavItem className="small">
                <a href={pdf.asset.url} target="_blank" rel="noreferrer">
                  Danish Collection Board
                </a>
              </NavItem>
            </CollapsedInfo>
          </NavItem>
        </div>
        <NavItem active={checkPath('journal')} onClick={handleNavItemClick}>
          <Link to="/journal">Journal</Link>
        </NavItem>
        <NavItem
          donate
          active={checkPath('donate')}
          style={{
            color: 'var(--red)',
            paddingBottom: isMobile && 'var(--spacing-XL)',
          }}
          onClick={handleNavItemClick}
        >
          <Link to="/donate">Donate</Link>
        </NavItem>
      </CollapsedNav>
    </StyledHeader>
  );
};

const StyledHeader = styled(motion.header)`
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: var(--blue);
  background: white;

  .logo {
    display: none !important;
  }

  .menu-btn {
    grid-column: 25;
  }

  h1 {
    grid-column: 1 / 23;
  }

  nav {
    text-align: right;
    grid-column: 1 / 26;

    ul {
      padding-top: calc(var(--v-spacing-S) * 2);

      li {
        font-family: var(--font-2);
        font-size: calc(var(--font-size-4) / 2.4);
        padding-bottom: var(--v-spacing-S);
      }

      .info {
        padding-top: calc(var(--v-spacing-S) * 3);
        padding-bottom: calc(var(--v-spacing-S) * 3);

        li {
          font-family: var(--font-1);
          align-self: flex-start;
          font-size: var(--font-size-2);
        }

        .small.top {
          margin-top: var(--spacing-M);
        }

        .small {
          font-size: var(--font-size-1);
        }
      }
    }

    span {
      padding-right: var(--spacing-XXXS);
      display: inline-block;
      transform: translateY(-10px);
    }
  }

  .active-page {
    text-decoration: underline;
  }

  @media ${media.L} {
    h1 {
      grid-column: 1 / 9;
    }

    .logo {
      display: block !important;
    }

    .logo-mobile {
      display: none !important;
    }

    nav {
      grid-column: 14 / 25;
      height: auto;
      font-size: var(--font-size-0);

      ul {
        padding-top: 0;
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: flex-end;
        padding-bottom: 2px;

        li {
          font-family: var(--font-1);
          font-size: var(--font-size-0);
          padding-bottom: 0;
        }

        .info {
          margin-top: var(--spacing-M);
          padding-top: 0;
          padding-bottom: 0;

          li {
            align-self: flex-start;
            font-size: var(--font-size-1);
          }

          .small.top {
            margin-top: var(--spacing-M);
          }

          .small {
            font-size: var(--font-size-0);
          }
        }

        .spacing-right {
          margin-right: calc(var(--v-spacing-S) * 3);
        }
      }

      span {
        transform: translateY(-4px);
      }
    }

    .active-page {
      text-decoration: none;
    }
  }

  /* SMALL DEVICES */
  @media (max-width: 335px) {
    .menu-btn {
      grid-column: 23;
    }

    h1 {
      grid-column: 1 / 22;
    }

    nav {
      grid-column: 1 / 24;
    }
  }
`;

export default Header;
