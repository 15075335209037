import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import HoverUnderline from '../atoms/hoverUnderline';

const NavItem = ({
  active,
  children,
  className,
  onClick,
  style,
  donate = false,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <StyledNavItem
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={className}
      onClick={onClick}
      style={style}
    >
      {children}
      <HoverUnderline active={hover || active} donate={donate} />
    </StyledNavItem>
  );
};

const StyledNavItem = styled.li`
  position: relative;
`;

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  donate: PropTypes.bool,
};

export default NavItem;
