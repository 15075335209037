import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const SmallArrow = ({ active, src }) => (
  <span>
    <motion.img
      src={src}
      alt="Small arrow"
      initial={{ rotate: 0 }}
      animate={{ rotate: active ? 180 : 0 }}
      transition={{ duration: 0.2 }}
    />
  </span>
);

SmallArrow.propTypes = {
  active: PropTypes.bool,
  src: PropTypes.string,
};

export default SmallArrow;
