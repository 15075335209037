import { createGlobalStyle } from 'styled-components';

const Variables = createGlobalStyle`

  :root {
    /* Colors */
    --blue: #081E77;
    --red: #FF2C46;

    /* Spacing */
    --spacing-XXXS: 0.8rem;
    --spacing-XXS: 1.2rem;
    --spacing-XS: 2rem;
    --spacing-S: 3rem;
    --spacing-M: 4rem;
    --spacing-L: 5rem;
    --spacing-XL: 10rem;
    --spacing-XXL: 20rem;
    /* --spacing-XL: 13rem;
    --spacing-XXL: 21rem; */

    /* Variable Spacing */
    /* Clamp Fallback */ --v-spacing-S: var(--spacing-XS);
    --v-spacing-S: clamp(var(--spacing-XXS), 2vw, var(--spacing-S));
    /* Clamp Fallback */ --v-spacing-M: 6rem;
    --v-spacing-M: clamp(var(--spacing-S), 5vw, var(--spacing-L));
    /* Clamp Fallback */ --v-spacing-L: 8.5rem;
    --v-spacing-L: clamp(var(--spacing-L), 7vw, var(--spacing-XL));

    /* Fonts */
    --font-1: 'NeueMontreal Medium', sans-serif;
    --font-2: 'NeueMontreal Bold', sans-serif;
    --font-size-0: 1.6rem;
    /* Clamp Fallback */ --font-size-1: calc(16px + (24 - 16) * (100vw - 375px) / (2560 - 375));
    --font-size-1: clamp(1.6rem, 2.5vw, 2.4rem);
    /* Clamp Fallback */ --font-size-2: calc(30px + (38 - 30) * (100vw - 375px) / (2560 - 375));
    --font-size-2: clamp(2.4rem, 3.5vw, 3.8rem);
    /* Clamp Fallback */ --font-size-3: calc(38px + (50 - 38) * (100vw - 375px) / (2560 - 375));
    --font-size-3: clamp(2.4rem, 4vw, 5rem);
    /* Clamp Fallback */ --font-size-4: calc(100px + (260 - 100) * (100vw - 375px) / (2560 - 375));
    --font-size-4: clamp(9rem, 15vw, 26rem);
    --letter-spacing: 0;

    /* Border */
    --border-width: 1px;
    --border: var(--border-width) solid var(--text-color);

    /* Layout */
    --grid-cols: 24;
    --grid-gap: var(--spacing-S);

    /* Misc */
  }
`;

export default Variables;
