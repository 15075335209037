import React from 'react';
import styled from 'styled-components';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router'; // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import HoverUnderline from '../atoms/hoverUnderline';
import { media } from '../../utils/media-queries';

const CookieBanner = () => {
  const location = useLocation();

  return (
    <StyledCookieBanner>
      <CookieConsent
        overlay
        overlayClasses="overlay"
        disableStyles
        location="bottom"
        enableDeclineButton
        flipButtons
        buttonText="Accept"
        declineButtonText="Deny"
        cookieName="gatsby-gdpr-google-analytics"
        hideOnAccept
        expires={182}
        contentClasses="text"
        buttonWrapperClasses="buttons"
        buttonClasses="btn"
        declineButtonClasses="decline-btn"
        onAccept={() => {
          initializeAndTrack(location);
        }}
      >
        <p className="bold-small title">We use cookies to gather statistics on this website</p>
        <p className="copy">
          By clicking "Accept" you will accept the use of cookies to gather statistics. <br />
          By clicking "Deny" we will not be using your information to gather statistics. <br /> A cookie will, hover, be set to remember you choice.
        </p>
        <p className="copy-mobile">
          By clicking "Accept" you will accept the use of cookies to gather statistics. By clicking "Deny" we will not be using your information to
          gather statistics. A cookie will, hover, be set to remember you choice.
        </p>
        <p className="link">
          Read about
          <Link to="/cookies">
            Cookies here
            <HoverUnderline active />
          </Link>
        </p>
      </CookieConsent>
    </StyledCookieBanner>
  );
};

const StyledCookieBanner = styled.div`
  overflow-y: hidden;

  .overlay {
    background: rgba(0, 0, 0, 0.1);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99 !important;
    overflow: hidden;
  }

  .CookieConsent {
    position: fixed;
    bottom: 0;
    left: var(--v-spacing-S);
    margin-right: var(--v-spacing-S);
    flex-direction: column;
    background: white;
    padding-bottom: var(--v-spacing-S);
    min-width: 50%;

    .text {
      flex: 0;
      margin: var(--v-spacing-S);

      .title {
        font-size: var(--font-size-1);
        padding-bottom: var(--v-spacing-S);
      }

      .copy,
      .copy-mobile,
      .link {
        color: var(--blue);
        font-size: var(--font-size-0);
        line-height: 1.2;
      }

      .copy {
        display: none;
      }

      .link {
        a {
          padding-left: 3px;
          padding-top: var(--v-spacing-S);
          position: relative;
          display: inline-block;
          text-decoration: underline;
        }
      }
    }

    .buttons {
      .btn,
      .decline-btn {
        background: #f2f2f2;
        color: var(--blue);
        border-radius: 50px;
        padding: var(--spacing-XXS) var(--spacing-S);
        margin-left: var(--v-spacing-S);
        margin-top: 0;

        :before {
          content: '→ ';
        }
      }

      .decline-btn {
        margin-left: var(--spacing-XXS);
      }
    }
  }

  .bg {
    width: 100%;
    overflow: hidden;
    background: rgba(0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100vh;
    opacity: 10%;
  }

  @media ${media.L} {
    max-width: 50%;

    .CookieConsent {
      .text {
        .copy {
          display: block;
        }

        .copy-mobile {
          display: none;
        }

        .link {
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
`;

CookieBanner.propTypes = {};

export default CookieBanner;
