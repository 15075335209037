import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const LayoutContext = createContext();

const LayoutProvider = ({ children }) => {
  const [navOpen, setNavOpen] = useState(false);
  const [tag, setTag] = useState();
  const [clickedCloseCrdits, setClickedCloseCrdits] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        darkenBg: navOpen,
        setDarkenBg: setNavOpen,
        clickedTag: tag,
        setClickedTag: setTag,
        clickedClose: clickedCloseCrdits,
        setClickedClose: setClickedCloseCrdits,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

LayoutProvider.propTypes = {
  children: PropTypes.node,
};

export default LayoutProvider;
