import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import GlobalStyles from '../../styles/GlobalStyles';
import Normalize from '../../styles/Normalize';
import Variables from '../../styles/Variables';
import Header from './header';
import Footer from './footer';
import LayoutProvider from './layoutProvider';
import Darken from '../atoms/darken';
import CookieBanner from '../molecules/cookieBanner';

const Layout = ({ children, index, className, location }) => (
  <>
    <LayoutProvider>
      {/**
       * Styles
       */}
      <Normalize />
      <GlobalStyles />
      <Variables />
      {/**
       * Content
       */}
      <CookieBanner />
      <Header index={index} />
      <Darken />
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.main
          className={className}
          key={location && `key${location.pathname}`}
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 100 }}
          transition={{ duration: 0.4, ease: [0, 0.55, 0.45, 1] }}
        >
          {children}
        </motion.main>
      </AnimatePresence>
      <Footer />
    </LayoutProvider>
  </>
);

Layout.propTypes = {
  children: PropTypes.node,
  index: PropTypes.bool,
  className: PropTypes.string,
  location: PropTypes.object,
};

export default Layout;
